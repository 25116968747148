import React, { type ComponentType } from 'react';
import { useIntl } from '@atlassian/jira-intl';
import type { AppPropsState } from '@atlassian/jira-servicedesk-common/src/model/types.tsx';
import { PageStyle } from '@atlassian/jira-servicedesk-spa-commons/src/common/utils/page-style/index.tsx';
import messages from './messages.tsx';
import { buildDefaultQueue } from './utils.tsx';

type Props = {
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	AgentView: ComponentType<any>;
	initialState: {
		appProps: AppPropsState;
	};
};

const NoQueuesView = ({ AgentView, ...agentViewProps }: Props) => {
	const { formatMessage } = useIntl();

	return (
		<PageStyle>
			<AgentView
				queue={buildDefaultQueue(
					agentViewProps.initialState.appProps.isProjectSimplified,
					formatMessage(messages.queueName),
				)}
				{...agentViewProps}
			/>
		</PageStyle>
	);
};

export default NoQueuesView;
