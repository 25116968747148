import log from '@atlassian/jira-common-util-logging/src/log.tsx';
import fireErrorAnalytics from '@atlassian/jira-errors-handling/src/utils/fire-error-analytics.tsx';

/* Maintains existing logic in the monolith. In the top level ErrorBoundary around ServicedeskAppBase we call this */
/* Rather than falling back to rendering via queues-module, if something goes horribly wrong we log the error and re-direct out of the SPA */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const onErrorHandler = (err: Error | string, callback: () => any) => {
	// eslint-disable-next-line no-console
	console.error(`Failing back due to unhandled error: ${JSON.stringify(err)}`);

	log.safeErrorWithoutCustomerData(
		'servicedesk.queues.queues-fe-view',
		`[JSDSPA-85] Attempting to fail back to non-spa due to unhandled error: ${JSON.stringify(err)}`,
	);

	fireErrorAnalytics({
		meta: {
			id: 'spaFallBack',
			packageName: 'jiraSpaAppsQueuesAppBase',
			teamName: 'jsd-shield',
		},
		// @ts-expect-error - TS2345 - Argument of type 'string | Error' is not assignable to parameter of type 'string | undefined'.
		error: new Error(err),
	});

	callback();
};

export type OnErrorHandler = typeof onErrorHandler;
