import memoizeOne from 'memoize-one';
import {
	DEFAULT_QUEUE_ID,
	DEFAULT_COLUMNS,
} from '@atlassian/jira-servicedesk-queues-common/src/constants/index.tsx';
import {
	toQueueId,
	type Column,
	type Queue,
} from '@atlassian/jira-servicedesk-queues-common/src/model/index.tsx';

const fieldIdToColumn: (fieldId: string) => Column = (fieldId) => ({
	title: '',
	id: fieldId,
	fieldType: fieldId,
	sortable: false,
});

export const buildDefaultQueue = memoizeOne(
	(isProjectSimplified: boolean, queueName: string): Queue => ({
		id: toQueueId(DEFAULT_QUEUE_ID),
		name: queueName,
		jql: isProjectSimplified
			? 'statusCategory in ("To Do", "In Progress")'
			: 'resolution = Unresolved',
		completeJql: '',
		order: -1,
		columns: DEFAULT_COLUMNS.map(fieldIdToColumn),
		isFavorite: false,
		isTransient: true,
		canBeHidden: true,
	}),
);
